<template>
  <div>
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-col class="d-flex justify-content-between mb-1">
          <h1 class="font-weight-bolder w-50">
            <b-skeleton
              height="25px"
              width="100%"
            />
          </h1>
          <div class="d-flex w-50 justify-content-end">
            <b-skeleton
              height="25px"
              width="20%"
              class="mr-2"
            />
            <b-skeleton
              height="25px"
              width="20%"
            />
          </div>
        </b-col>
      </template>
      <b-row class="mb-1">
        <b-col class="d-flex justify-content-between mb-1">
          <h1 class="font-weight-bolder">
            {{ supplier.name }}
          </h1>
        </b-col>
        <b-col
          class="d-flex align-items-start justify-content-start justify-content-sm-end"
        >
          <b-button
            :to="{
              name: 'supplierProductsCatalogs',
              params: { id: supplier.id, type: 'proveedor' },
            }"
            variant="primary"
            class="mr-1"
          >{{ $t('products') }}</b-button>
          <b-button
            :to="{
              name: 'registerBranch',
              params: { id: this.$route.params.id },
            }"
            variant="primary"
            class="text-nowrap"
          >{{ $t('register', { type: $t('branch') }) }}</b-button>
        </b-col>
      </b-row>
    </b-skeleton-wrapper>
    <!-- <b-row>
      <b-col sm="12">
        <card-statistics-group
          icon="CpuIcon"
          statistic="86%"
          :statistic-title="$t('stats')"
        />
      </b-col>
    </b-row> -->
    <b-card
      no-body
      class="overflow-hidden"
    >
      <b-row class="no-gap">
        <b-col
          cols="12"
          md="3"
          class="pr-md-0"
        >
          <BranchTable v-if="false" />
          <b-card-header header-bg-variant="light-primary">
            <b-card-title class="font-weight-bolder">
              {{ $t('branches') }}
            </b-card-title>
          </b-card-header>

          <b-skeleton-wrapper :loading="loading">
            <template #loading>
              <b-list-group>
                <b-list-group-item
                  v-for="(branch, n) in [...Array(1).keys()]"
                  :key="n"
                >
                  <h4 class="m-0">
                    <b-skeleton
                      class="m-0"
                      height="20px"
                      width="100%"
                    />
                  </h4>
                </b-list-group-item>
              </b-list-group>
            </template>
            <b-list-group>
              <b-list-group-item
                v-for="(branch, n) in branches"
                :key="branch.id"
                class="d-flex align-items-center justify-content-between"
                :to="{
                  name: 'branch',
                  params: { supplierId: $route.params.id, branchId: branch.id },
                }"
                @mouseover="highlightGeofence = n"
                @mouseleave="highlightGeofence = null"
              >
                <h4
                  :class="branch.active_status ? '' : 'text-danger'"
                  class="mb-0"
                >
                  {{ branch.name }}
                </h4>
                <b-badge
                  v-if="branch.is_express"
                  variant="success"
                >
                  {{ $t('express') }}
                </b-badge>
              </b-list-group-item>
            </b-list-group>
          </b-skeleton-wrapper>
        </b-col>
        <b-col class="pl-md-0">
          <gmap-map
            ref="map"
            :center="center"
            :zoom="12"
            style="width: 100%; height: 650px"
          >
            <gmap-polygon
              v-for="(area, n) in otherPaths"
              :key="`area-${n}`"
              ref="polygon2"
              :paths="area"
              :options="geofenceOptions(n)"
              @click="
                $router.push({
                  name: 'branch',
                  params: {
                    supplierId: $route.params.id,
                    branchId: branches[n].id,
                  },
                })
              "
              @mouseover="highlightGeofence = n"
              @mouseleave="highlightGeofence = null"
            />
          </gmap-map>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex'
// import CardStatisticsGroup from '@/components/cards/CardStatisticsGroup.vue'
import BranchTable from '@/components/tables/BasicTable.vue'

export default {
  components: {
    // CardStatisticsGroup,
    BranchTable,
  },
  data() {
    return {
      loading: false,
      center: { lat: 25.673281129320067, lng: -100.3161126 },
      paths: [],
      otherPaths: [],
      polygonGeojson: '',
      polygonOptions: {
        fillColor: '#340668',
        fillOpacity: 0.45,
        strokeWeight: 0,
        strokeColor: '#999',
      },
      selectedPolygonOptions: {
        fillColor: '#0a0',
        fillOpacity: 0.45,
        strokeWeight: 0,
        strokeColor: '#999',
      },
      highlightGeofence: null,
    }
  },
  computed: {
    ...mapState('suppliers', ['selectedSupplier', 'supplier']),
    ...mapState('auth', ['currentUser']),
    ...mapState('app', ['isSmallScreen']),
    ...mapGetters('branches', ['branches']),
  },
  created() {
    this.loading = true
    this.fetchSupplier(this.$route.params.id).then(response => {
      if (response.active_status === false) {
        this.$router.push({
          name: 'editSupplier',
          params: { supplierId: response.id },
        })
      }
      this.center.lat = parseFloat(response.address.lat)
      this.center.lng = parseFloat(response.address.lng)
    })
    this.fetchBranches({ supplierId: this.$route.params.id }).then(response => {
      response.data.forEach(x => {
        this.readGeojson(x.geofence)
      })
      this.loading = false
    })
  },
  destroyed() {
    // this.clearProvider('')
  },
  methods: {
    geofenceOptions(n) {
      if (this.highlightGeofence === n) {
        return this.selectedPolygonOptions
      }
      return this.polygonOptions
    },
    ...mapActions('suppliers', ['fetchSupplier']),
    ...mapActions('branches', ['fetchBranches']),
    ...mapMutations('suppliers', {
      selectProvider: 'SET_SELECTED_SUPPLIER',
      clearProvider: 'SET_SELECTED_SUPPLIER',
    }),
    readGeojson($event) {
      try {
        const v = $event
        const paths = v.coordinates.map(linearRing => linearRing
          .slice(0, linearRing.length - 1)
          .map(([lng, lat]) => ({ lat, lng })))
        this.otherPaths.push(paths)
        this.errorMessage = null
      } catch (err) {
        this.errorMessage = err.message
      }
    },
  },
}
</script>

<style></style>
