var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-skeleton-wrapper',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-col',{staticClass:"d-flex justify-content-between mb-1"},[_c('h1',{staticClass:"font-weight-bolder w-50"},[_c('b-skeleton',{attrs:{"height":"25px","width":"100%"}})],1),_c('div',{staticClass:"d-flex w-50 justify-content-end"},[_c('b-skeleton',{staticClass:"mr-2",attrs:{"height":"25px","width":"20%"}}),_c('b-skeleton',{attrs:{"height":"25px","width":"20%"}})],1)])]},proxy:true}])},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"d-flex justify-content-between mb-1"},[_c('h1',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.supplier.name)+" ")])]),_c('b-col',{staticClass:"d-flex align-items-start justify-content-start justify-content-sm-end"},[_c('b-button',{staticClass:"mr-1",attrs:{"to":{
            name: 'supplierProductsCatalogs',
            params: { id: _vm.supplier.id, type: 'proveedor' },
          },"variant":"primary"}},[_vm._v(_vm._s(_vm.$t('products')))]),_c('b-button',{staticClass:"text-nowrap",attrs:{"to":{
            name: 'registerBranch',
            params: { id: this.$route.params.id },
          },"variant":"primary"}},[_vm._v(_vm._s(_vm.$t('register', { type: _vm.$t('branch') })))])],1)],1)],1),_c('b-card',{staticClass:"overflow-hidden",attrs:{"no-body":""}},[_c('b-row',{staticClass:"no-gap"},[_c('b-col',{staticClass:"pr-md-0",attrs:{"cols":"12","md":"3"}},[(false)?_c('BranchTable'):_vm._e(),_c('b-card-header',{attrs:{"header-bg-variant":"light-primary"}},[_c('b-card-title',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('branches'))+" ")])],1),_c('b-skeleton-wrapper',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-list-group',_vm._l(([].concat( Array(1).keys() )),function(branch,n){return _c('b-list-group-item',{key:n},[_c('h4',{staticClass:"m-0"},[_c('b-skeleton',{staticClass:"m-0",attrs:{"height":"20px","width":"100%"}})],1)])}),1)]},proxy:true}])},[_c('b-list-group',_vm._l((_vm.branches),function(branch,n){return _c('b-list-group-item',{key:branch.id,staticClass:"d-flex align-items-center justify-content-between",attrs:{"to":{
                name: 'branch',
                params: { supplierId: _vm.$route.params.id, branchId: branch.id },
              }},on:{"mouseover":function($event){_vm.highlightGeofence = n},"mouseleave":function($event){_vm.highlightGeofence = null}}},[_c('h4',{staticClass:"mb-0",class:branch.active_status ? '' : 'text-danger'},[_vm._v(" "+_vm._s(branch.name)+" ")]),(branch.is_express)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('express'))+" ")]):_vm._e()],1)}),1)],1)],1),_c('b-col',{staticClass:"pl-md-0"},[_c('gmap-map',{ref:"map",staticStyle:{"width":"100%","height":"650px"},attrs:{"center":_vm.center,"zoom":12}},_vm._l((_vm.otherPaths),function(area,n){return _c('gmap-polygon',{key:("area-" + n),ref:"polygon2",refInFor:true,attrs:{"paths":area,"options":_vm.geofenceOptions(n)},on:{"click":function($event){return _vm.$router.push({
                name: 'branch',
                params: {
                  supplierId: _vm.$route.params.id,
                  branchId: _vm.branches[n].id,
                },
              })},"mouseover":function($event){_vm.highlightGeofence = n},"mouseleave":function($event){_vm.highlightGeofence = null}}})}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }