<template>
  <b-card
    class="overflow-hidden"
    no-body
  >
    <b-table
      :items="items"
      :fields="tableFields"
    >
      <template #cell(name)="data">
        <div v-if="tableType === 'provider'">
          <router-link :to="{ name: 'branches', params: { supplierId: $route.params.id, branchId: data.item.id } }">
            <span
              class="text-primary font-weight-bolder"
              @click="selectProvider(data.value)"
            >{{ data.value }}</span>
          </router-link>
        </div>
        <div v-else-if="tableType === 'branches'">
          <router-link :to="{ name: 'branch', params: { supplierId: $route.params.id, branchId: data.item.id } }">
            <span
              class="text-primary font-weight-bolder"
              @click="selectBranch(data.item);"
            >{{ data.value }}</span>
          </router-link>
        </div>
      </template>
      <template #cell(actions)>
        <div>
          <b-button
            class="mr-1"
            size="sm"
            variant="link"
          >
            <feather-icon
              size="18"
              icon="MoreVerticalIcon"
            />
          </b-button>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  components: {},

  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    tableType: {
      type: String,
      default() {
        return 'provider'
      },
    },
  },

  data() {
    return {
      fieldsProvider: [
        {
          key: 'name',
          label: this.$t('nameOf', { type: this.$t('provider') }),
        },
        {
          key: 'representative',
          label: this.$t('representative'),
        },
        {
          key: 'email',
          label: this.$t('email'),
        },
        {
          key: 'location',
          label: this.$t('location'),
        },
        {
          key: 'actions',
          label: this.$t('actions'),
        },
      ],
      fieldsBranches: [
        {
          key: 'name',
          label: this.$t('branch'),
        },
        {
          key: 'representative',
          label: this.$t('representative'),
        },
        {
          key: 'email',
          label: this.$t('email'),
        },
        {
          key: 'location',
          label: this.$t('location'),
        },
        {
          key: 'actions',
          label: this.$t('actions'),
        },
      ],
    }
  },

  computed: {
    tableFields() {
      if (this.tableType === 'provider') return this.fieldsProvider
      if (this.tableType === 'branches') return this.fieldsBranches
      return []
    },
  },

  methods: {
    ...mapMutations('suppliers', {
      selectProvider: 'SET_SELECTED_SUPPLIER',
    }),
    ...mapMutations('branches', {
      selectBranch: 'SET_SELECTED_BRANCH',
    }),
  },
}
</script>
